import React, { useRef, useState } from 'react';
import { useAppData } from '../../../context/AppContext';
import { PinPrimaryIcon } from '../../../assets';
import { importFilesByCampaignId } from '../../../core/requests/requests';
import { FileProcessorProps } from './startCampaignModels';
import { InfoModal } from '../../../components';
import { getFileCharacterCount } from '../../../helpers/campaignHelpers';
import { set } from 'date-fns';

const FileProcessor: React.FC<FileProcessorProps> = ({ setFileToUpload }) => {
  const { currentCampaign, setShowModalBackground } = useAppData();
  const currentCampaignId = currentCampaign?.id;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setShowModalBackground(false);
    setErrorMessage(null);
  };

  const handleOpenErrorModal = () => {
    setShowErrorModal(true);
    setShowModalBackground(true);
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open the file dialog
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]; // Get the selected file
    if (file) {
      const allowedTypes = [
        'application/pdf',
        'application/msword', // Word (.doc)
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word (.docx)
        'text/plain', // Text files
        'application/vnd.ms-excel', // Excel (.xls)
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel (.xlsx)
        'text/csv', // CSV files
      ];

      if (!allowedTypes.includes(file.type)) {
        setErrorMessage('Unsupported file type. Please select a valid file.');
        handleOpenErrorModal();
        return;
      }

      const charCount = await getFileCharacterCount(file);

      // Check if the file exceeds 2500 characters
      if (charCount > 2500) {
        setErrorMessage('The document exceeds the 2500 symbol limit.');
        handleOpenErrorModal();
        setFileToUpload(null);
        return;
      }

      setFileName(`${file?.name}`);

      if (currentCampaignId) {
        try {
          const { data } = await importFilesByCampaignId(
            file,
            currentCampaignId
          );
          if (data) {
            setErrorMessage('File uploaded successfully');
            handleOpenErrorModal();
          }
        } catch (error) {
          setErrorMessage(`Error uploading file:', ${error}`);
          handleOpenErrorModal();
        }
      } else {
        setFileToUpload(file);
      }
    } else {
      setFileName(null);
      setFileToUpload(null);
    }
  };

  return (
    <>
      <div className="d-flex-start-start mt-1_5rem w-100">
        <span className="fw-600 fs1_125rem">Knowledge Hub</span>
        <div className="d-flex-row w-100 mt-0_5rem">
          <div
            className="d-flex-row mr-1rem icon-hovered"
            onClick={handleImportClick}
          >
            <span className="c-0062ff fw-500 mr-0_375rem">Import file</span>
            <PinPrimaryIcon className="w-1_25rem h-1_25rem" />
          </div>
          <span>{fileName}</span>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,.csv"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      {showErrorModal ? (
        <InfoModal
          title={errorMessage || 'Unknown Error'}
          onClose={handleCloseErrorModal}
        />
      ) : null}
    </>
  );
};

export default FileProcessor;
